import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../SessionContext.tsx";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";
import IconUserCircle from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/user-circle.tsx";
import IconChevronLeft from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-left.tsx";
import IconUser from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/user.tsx";
import IconBuildingStore from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/building-store.tsx";
import IconFileSpreadsheet from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/file-spreadsheet.tsx";
import IconMapPin from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/map-pin.tsx";
import IconMail from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/mail.tsx";
import { getServer } from "../Server.tsx";

export function WarningNoticeLong({ onChange }){

	return (<>
		<_WarningNoticeLong onChange={onChange} />
		</>
	);
}

export class _WarningNoticeLong extends Component {
	static contextType = SessionContext;
	
	render() {
		let that = this;
		
		return <>
			<div class="container" style="">
				<div class="py-4 my-4">
				</div>
			</div>
			<div class="fixed-top" style="background-color: #fde600;">
				<div class="container">
					<div class="pt-3 pb-2 lh-sm fw-bold text-center font-lct fw-bold">
						<div class="vmini fs-fdjwarning-bold mb-1">
							LES JEUX D'ARGENT ET DE HASARD PEUVENT ÊTRE DANGEREUX : PERTES D'ARGENT, CONFLITS FAMILIAUX, ADDICTION...
						</div>
						<div class="vtiny fs-fdjwarning-regular">
							RETROUVEZ NOS CONSEILS SUR JOUEURS-INFO-SERVICE.FR<br/>(09 74 75 13 13 - APPEL NON SURTAXÉ)
						</div>
						<div class="pb-1 d-flex justify-content-center align-items-center">
							<div>
								<img
									src="/f-18y.svg"
									class="w-10 h-6"
									style="bottom:0;border-bottom-left-radius:5px;border-top-right-radius:5px;"
									alt="Française des jeux"
								/>
							</div>
							<div>
								<img
									src="/gouvt.png"
									class="w-10 h-6"
									style="bottom:0;border-bottom-left-radius:5px;border-top-right-radius:5px;"
									alt="Française des jeux"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>;
	}
}